<template>

    <auth-content>
      <el-header :title="$t('modules.academy.course.title')" slot="header">
        <div slot="actions">
          <el-header-action :disabled="loading" size="xs" icon="plus3" :label="$t('modules.academy.course.button_text_add')" @click.native="openCreateModal()" v-if="worldCanManageTemplates" />
        </div>
      </el-header>

      <div v-if="Object.keys(content).length > 0" class="row">
        <div v-for="academycourse in contentFiltered" class="col-xxl-6 mb-3">
          <div class="card academycourse-card cursor-pointer mb-0" @click="academycoursePencilHovered ? false : openAcademyCourse(academycourse)">
            <div class="academycourse-name card-header py-3">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <el-avatar size="medium" :src="require('@/assets/images/categories/' + academycourse.data.category + '.svg')" />
                  <span class="ml-2 h5 font-weight-bold">{{ getCategoryTitle(academycourse.data.category) }}</span>
                </div>
                <i
                  class="icon-pencil cursor-pointer text-grey py-1"
                  v-if="worldCanManageTemplates"
                  @click="openEditModal(academycourse)"
                  @mouseenter="academycoursePencilHovered = true"
                  @mouseleave="academycoursePencilHovered = false"
                />
              </div>
            </div>
            <div class="card-body py-3">
              <h3 class="mt-0 mb-1">{{ academycourse.data.name }}</h3>
              <div class="row align-items-start">
                <div class="col-md-6">
                  <p class="text-grey">{{ $tc('modules.academy.course.nb_lessons', academycourse.data.content.length) }}</p>
                  <p>{{ academycourse.data.description }}</p>
                </div>
                <div class="col-md-6">
                  <div class="bg-light p-3 rounded">
                    <p class="font-weight-bold text-info">{{ $t('modules.academy.course.details') }}</p>
                    <div
                      v-for="(content, contentIndex) in academycourse.data.content"
                      class="py-1"
                    >
                      {{ content.title }}
                      <span v-for="(sheet, sheetIndex) in content.sheets">
                        <i
                          :class="{
                            'ml-1': true,
                            'icon-circle2 text-grey': !isCompleted(sheet),
                            'icon-checkmark-circle text-success': isCompleted(sheet),
                          }"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <el-empty v-else :title="$t('modules.academy.course.empty_title')" :text="$t('modules.academy.course.empty_text')"></el-empty>


      <div slot="sidebar">

        <el-filter-search ref="filterSearch"></el-filter-search>
        <el-filters :filters="filters"></el-filters>

      </div>

      <el-modal
        size="fullwidth"
        :title="currentAcademyCourseTitle"
        :subtitle="getCategoryTitle(currentAcademyCourseSubtitle)"
        id="academy-course-view-modal"
      >
        <div class="container-fluid">
          <div class="row" v-if="currentAcademyCourse && currentAcademyCourseLoaded">
            <div class="col-md-3 bg-light">
              <div class="p-2 summary position-sticky sticky-top">
                <div
                  class="pt-2 cursor-pointer"
                  v-for="(group, groupIndex) in currentAcademyCourse.data.content"
                >
                  <h5 class="font-weight-bold">{{group.title}}</h5>
                  <div
                    class="h6 pt-2 cursor-pointer pl-3 summary-courses position-relative"
                    v-for="(slide, slideIndex) in group.sheets"
                    :class="currentAcademyCourseSheetsIndexesReversed[slide] == currentAcademyCourseStep ? 'text-info' : ''"
                    @click="currentAcademyCourseStep = currentAcademyCourseSheetsIndexesReversed[slide]"
                  >
                    <i
                      :class="{
                        'mr-1': true,
                        'icon-circle2': !isCompleted(slide),
                        'icon-checkmark-circle': isCompleted(slide),
                        'text-grey': currentAcademyCourseSheetsIndexesReversed[slide] != currentAcademyCourseStep && !isCompleted(slide),
                        'text-success': isCompleted(slide),
                      }"></i>
                    <span>{{ currentAcademyCourse.data.content_detail[slide].name }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-9">

              <div v-if="!currentAcademyCourseLoaded" class="text-center py-4">
                <loader />
              </div>
              <sheet-pilote :sheet="currentAcademyCourseSlide" v-else />
              <div class="text-center">
                <div
                  v-if="!isCompleted(currentAcademyCourseSlide.id)"
                  @click="completeAcademyCourse(currentAcademyCourseSlide.id)"
                  class="my-4 py-3 w-100 h5 bg-success text-white rounded cursor-pointer"
                >
                  <div v-if="!completeAcademyCourseLoading">
                    {{ $t('modules.academy.course.lesson_complete') }}
                    <i class="ml-2 icon-checkmark-circle"></i>
                  </div>
                  <div v-else>
                    <loader />
                  </div>
                </div>
                <div v-else class="py-3 text-center h5 text-success">
                    {{ $t('modules.academy.course.lesson_completed') }}
                    <i class="ml-2 icon-checkmark-circle"></i>
                </div>
                <div class="row mt-3">
                  <div class="col text-left">
                    <div v-if="currentAcademyCourseStep > 0" @click="goPrevLesson()" class="btn btn-outline-secondary">
                      <i class="icon-arrow-left8"></i>
                      <span class='pl-1 d-none d-md-inline-block'>{{ $t('modules.academy.course.lesson_previous') }}</span>
                    </div>
                  </div>
                  <div class="col text-right">
                    <div v-if="currentAcademyCourseSheetsIndexesReversed[currentAcademyCourseSlide.id] < currentAcademyCourseSheetsIndexes.length - 1" @click="goNextLesson()" class="btn btn-outline-secondary">
                      <span class='pr-1 d-none d-md-inline-block'>{{ $t('modules.academy.course.lesson_next') }}</span>
                      <i class="icon-arrow-right8"></i>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div v-else class="text-center">
            <loader />
          </div>
        </div>
      </el-modal>


      <el-modal
        size="fullwidth"
        :title="academycourseModalEdit && academycourseModalEdit.id ? $t(`common.edit`) : $t(`modules.academy.course.button_text_add`)"
        id="academy-course-edit-modal"
      >
        <div class="container" v-if="academycourseModalEdit && academycourseModalEdit.world_id != null && isCurrentWorldOwner">
          <div class="row">
            <div class="col-lg-6">
              <label class="mt-2 font-weight-bold" for="name">{{ $t('modules.academy.course.fields.name') }}</label>
              <input
                v-model="academycourseModalEdit.data.name"
                :required="true"
                type="text"
                class="form-control"
                id="name"
                :placeholder="$t('modules.academy.course.fields.name')"
              />
              <div v-if="!academycourseModalEdit.id && !academycourseModalEdit.data.category">
                <label class="mt-2 font-weight-bold" for="name">{{ $t('modules.academy.course.fields.category') }}</label>
                <el-select
                  v-model="academycourseModalEdit.data.category"
                  :required="true"
                  :options="academySheetsCategoriesOptions"
                  :placeholder="$t('modules.academy.course.fields.category')"
                />
              </div>
              <div v-else class="pt-2">
                <el-avatar :src="academySheetsCategoriesOptions[academycourseModalEdit.data.category].avatar" />
                <span class="ml-2 font-weight-bold">{{ academySheetsCategoriesOptions[academycourseModalEdit.data.category].title }}</span>
              </div>
              <label class="mt-2 font-weight-bold" for="name">{{ $t('modules.academy.course.fields.description') }}</label>
              <textarea
                v-model="academycourseModalEdit.data.description"
                :required="true"
                type="text"
                class="form-control"
                id="description"
                :placeholder="$t('modules.academy.course.fields.description')"
                rows="10"
              />
            </div>
            <div class="col-lg-6">
              <div v-if="academycourseModalEdit.data.category">
                <label class="mt-2 font-weight-bold" for="name">{{ $t('modules.academy.course.fields.content') }}</label>
                <div v-for="(contentGroup, groupId) in academycourseModalEdit.data.content" class="mb-2 content-form-group" @click="currentGroupId = groupId">
                  <input class="form-control" type="text" v-model="contentGroup.title" @blur="checkIfGroupIsEmpty(groupId)">
                  <div class="pl-3">

                    <draggable class="" :list="contentGroup.sheets" group="sheets" handle=".handle">
                      <div v-for="(sheetId, sheetIndex) in contentGroup.sheets" class="py-1 my-1 d-flex align-items-center justify-content-between">
                        <div>
                          <i class="handle icon-menu7 small text-grey mr-1 cursor-move" />
                          {{ academySheets[sheetId].name }}
                        </div>
                        <i
                          class="icon-cross3 text-danger ml-1 cursor-pointer"
                          @click="deleteSheetFromGroup(groupId, sheetIndex)"
                        />
                      </div>
                    </draggable>
                    <div class="my-1">
                      <el-select
                        :ref="'select-groupsheet-' + groupId"
                        :required="true"
                        :options="academySheetsOptions"
                        :placeholder="$t('modules.academy.course.form.content.placeholder')"
                        :callback="addContentToGroup"
                      />
                    </div>

                  </div>
                </div>
                <div class="btn btn-sm btn-outline-success my-1" @click="addContentGroup()"><i class="icon-plus2" /> {{ $t('modules.academy.course.form.group.add') }}</div>
              </div>
              <div v-else class="mt-3 mt-lg-4 pt-lg-2">
                <el-alert type="info">{{ $t('modules.academy.course.form.content.choose_category') }}</el-alert>
              </div>
            </div>
          </div>

          <div class="text-center col-12 mt-3">
            <div v-if="!academycourseModalEditLoading && academycourseModalEdit.id" @click.prevent="submitDataForm" class="btn btn-success text-white">{{ $t('common.edit') }}</div>
            <div v-if="!academycourseModalEditLoading && !academycourseModalEdit.id" @click.prevent="submitDataForm" class="btn btn-success text-white">{{ $t('common.validate') }}</div>
            <div v-if="academycourseModalEditLoading" class="text-gray pt-1 pb-2"><loader /></div>
            <div>
              <div v-if="!academycourseModalEditLoading && academycourseModalEdit.id" @dblclick.prevent="deleteAcademyCourse" class="btn btn-link btn-sm text-danger">{{ $t('common.dbl_click_delete') }}</div>
            </div>
          </div>

        </div>

      </el-modal>

    </auth-content>

</template>

<script>

import draggable from 'vuedraggable'
import Fuse from 'fuse.js'
import _ from 'lodash'
import store from '@/store'
import SheetPilote from '@/../Modules/Quest/components/SheetPilote'

import {
  ACADEMY_COURSE_GET_SHEETS,
  ACADEMY_COURSE_INDEX,
  ACADEMY_COURSE_GET,
  ACADEMY_COURSE_CREATE,
  ACADEMY_COURSE_EDIT,
  ACADEMY_COURSE_COMPLETE,
  ACADEMY_COURSE_GET_COMPLETED,
  ACADEMY_COURSE_DELETE,
} from '../mutations-types'

export default {
  name: 'AcademyIndex',
  components: {
    draggable,
    SheetPilote,
  },
  data () {
    return {
      academycourseModalEdit: null,
      academycourseModalEditLoading: false,
      academycoursePencilHovered: false,
      academySheets: null,
      completedAcademyCourses: null,
      currentAcademyCourseLoaded: false,
      currentAcademyCourse: null,
      currentAcademyCourseStep: 0,
      completeAcademyCourseLoading: false,
      loading: false,
      fields: false,
      content: false,
      columns: {
        name: this.$t('modules.academy.course.fields.name'),
      },
      filters: false,
      currentGroupId: null,
      blankContentGroup: {
        title: this.$t('modules.academy.course.form.group.default_name'),
        sheets: [],
      },
    }
  },
  mounted () {
    this.changeSidebarRight(!this.areFiltersClean)
    if (this.worldCanManageTemplates) {
      this.$store.dispatch('academy/' + ACADEMY_COURSE_GET_SHEETS, this.$route.params.world).then(academySheets => {
        this.academySheets = _.keyBy({...academySheets}, 'id')
      })
    }
    this.$store.dispatch('academy/' + ACADEMY_COURSE_GET_COMPLETED, this.$route.params.world).then(completedAcademyCourses => {
      this.completedAcademyCourses = {...completedAcademyCourses}
    })

  },
  methods: {
    addContentGroup () {
      this.academycourseModalEdit.data.content.push(_.cloneDeep(this.blankContentGroup))
    },
    addContentToGroup (cid) {
      this.academycourseModalEdit.data.content[this.currentGroupId].sheets.push(cid)
      // this.$refs['select-groupsheet-' + this.currentGroupId][0].value = null
    },
    deleteSheetFromGroup (groupId, sheetIndex) {
      this.academycourseModalEdit.data.content[groupId].sheets.splice(sheetIndex, 1)
    },
    openEditModal (academycourse) {
      this.academycourseModalEdit = _.cloneDeep(academycourse)
      this.openModal('academy-course-edit-modal')
    },
    deleteGroup (groupId) {
      this.academycourseModalEdit.data.content.splice(groupId, 1)
    },
    checkIfGroupIsEmpty (groupId) {
      let groupData = this.academycourseModalEdit.data.content[groupId]
      let groupHasTitle = groupData.title && groupData.title != ''
      let groupHasSheets = groupData.sheets && groupData.sheets.length > 0
      if (!groupHasTitle && !groupHasSheets) {
        this.deleteGroup(groupId)
      }
    },
    openCreateModal (academycourse) {
      this.academycourseModalEdit = {
        world_id: this.$route.params.world,
        data: {
          category: null,
          description: '',
          name: '',
          content: [_.cloneDeep(this.blankContentGroup)],
        },
      }
      this.openModal('academy-course-edit-modal')
    },
    deleteAcademyCourse () {
      this.academycourseModalEditLoading = true
      this.$store.dispatch('academy/' + ACADEMY_COURSE_DELETE, {
        world_id: this.$route.params.world,
        id: this.academycourseModalEdit.id,
      }).then(() => {
        this.academycourseModalEditLoading = false
        this.notifSuccess(this.$t('modules.academy.course.messages.delete_success'))
        let foundIndex = Object.keys(this.content).findIndex(elem => this.content[elem].id == this.academycourseModalEdit.id)
        this.closeModal('academy-course-edit-modal')
        this.$delete(this.content, foundIndex)
        this.loadFilters()
      })
      .catch(error => {
        this.academycourseModalEditLoading = false
        this.notifError(error)
      })
    },
    submitDataForm () {
      this.academycourseModalEditLoading = true
      let action = 'academy/' + ACADEMY_COURSE_CREATE
      if (this.academycourseModalEdit.id) {
        action = 'academy/' + ACADEMY_COURSE_EDIT
      }

      let dataToSend = _.pick(this.academycourseModalEdit, ['world_id', 'data', 'id'])

      this.$store.dispatch(action, dataToSend).then(data => {
        this.academycourseModalEditLoading = false
        if (this.academycourseModalEdit.id) {
          this.notifSuccess(this.$t(`modules.academy.course.messages.edit_success`))
          let foundIndex = Object.keys(this.content).findIndex(elem => this.content[elem].id == data.data.id)
          this.$set(this.content, foundIndex, data.data);
        } else {
          this.$set(this.content, Object.keys(this.content).length, data.data)
          this.loadFilters()
          this.notifSuccess(this.$t(`modules.academy.course.messages.add_success`))
        }
        this.loadFilters()
        this.closeModal('academy-course-edit-modal')
      })
      .catch(error => {
        this.academycourseModalEditLoading = false
        this.notifError(error)
      })
    },
    goPrevLesson() {
      this.currentAcademyCourseStep--
      document.getElementById('academy-course-view-modal').scrollTo(0,0)
    },
    goNextLesson() {
      this.currentAcademyCourseStep++
      document.getElementById('academy-course-view-modal').scrollTo(0,0)
    },
    openAcademyCourse(academycourse) {
      this.currentAcademyCourseLoaded = false
      this.currentAcademyCourse = academycourse
      this.openModal('academy-course-view-modal')

      this.$store.dispatch('academy/' + ACADEMY_COURSE_GET, {
        world_id: this.$route.params.world,
        id: this.currentAcademyCourse.id,
      }).then(academycourse => {
        this.currentAcademyCourse = academycourse
        this.currentAcademyCourseStep = 0

        this.currentAcademyCourseSheetsIndexes.every((elem, index) => {
          if (this.isCompleted(elem)) {
            this.currentAcademyCourseStep = index + 1;
            return true
          } else {
            return false
          }
        })
        if (this.currentAcademyCourseStep >= this.currentAcademyCourse.data.content.length) {
          this.currentAcademyCourseStep = 0
        }
        this.currentAcademyCourseLoaded = true
      })
    },
    completeAcademyCourse(contentId) {
      if (this.completeAcademyCourseLoading) {
        return
      }

      this.completeAcademyCourseLoading = true
      this.$store.dispatch('academy/' + ACADEMY_COURSE_COMPLETE, {
        world_id: this.$route.params.world,
        id: this.currentAcademyCourseSlide.id,
      }).then(response => {
        this.completedAcademyCourses = response
        this.completeAcademyCourseLoading = false
      })
    },
    isCompleted(contentId) {
      if (!this.completedAcademyCourses) {
        return false
      }
      if (this.completedAcademyCourses.data && this.completedAcademyCourses.data.completed && this.completedAcademyCourses.data.completed[contentId]) {
        return true
      }
      return false
    },
    changeSidebarRight(val = null) {
      if (val === null) {
        val = !this.isSidebarRightOpened
      }
      this.$store.commit('global/changeSidebarRight', val)
    },
    cleanFilters () {
      _.forEach(this.filters, filter => {
        _.forEach(filter.options, option => {
          option.value = false
        })
      })
    },
    loadFilters () {
      let content = {...this.formatContent()}
      this.filters = Object.assign({}, this.filters, [
        {
          title: this.$t('modules.academy.course.fields.category'),
          key: 'filter_category',
          options: this.getFilterOptions(content, 'filter_category'),
        }
      ])
    },
    formatContent () {
      let contentArray = []
      if (this.content) {
        // Get content
        _.map(this.content, (elem) => {
          let content = elem
          content.filter_category = content.data.category
          contentArray.push(content);
        })
      }
      return contentArray
    },
    getCategoryTitle (categoryLabel) {
      return this.$te('modules.quest.categories.' + categoryLabel) ? this.$t('modules.quest.categories.' + categoryLabel) : categoryLabel
    }
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('academy/' + ACADEMY_COURSE_INDEX, to.params.world).then(content => {
        next(vm => {
          vm.content = {...content}
          vm.loadFilters()
        })
    })
  },
  computed: {
    worldCanManageTemplates () {
      return this.$store.getters['auth/worldCanManageTemplates'];
    },
    academySheetsOptions () {
      if (!this.academySheets || !this.academycourseModalEdit.data.category) {
        return []
      } else {
        let contentAlreadySelected = []
        _.forEach(this.academycourseModalEdit.data.content, contentGroup => {
          contentAlreadySelected = contentAlreadySelected.concat(contentGroup.sheets.map(Number))
        })

        let contentList = _.filter(this.academySheets, content => {
          return content.category == this.academycourseModalEdit.data.category && !contentAlreadySelected.includes(content.id)
        })
        return [{
                    title: this.$t('modules.academy.course.form.content.placeholder'),
                    value: '',
               }].concat(_.map(contentList, content => {
                  return {
                    title: content.name,
                    value: content.id,
                    category: content.category,
                    detail: content.level || null,
                  }
                }))
      }
    },
    academySheetsCategoriesOptions () {
      if (!this.academySheets) {
        return {}
      } else {
        let categories = {}
        _.forEach(this.academySheets, content => {
          if (content.category) {
            categories[content.category] = {
              avatar: require('@/assets/images/categories/' + content.category + '.svg'),
              title: this.getCategoryTitle(content.category),
              value: content.category,
            }
          }
        })
        return categories
      }
    },
    currentAcademyCourseSheetsIndexes () {
      if (this.currentAcademyCourse) {
        let indexes = []
        _.forEach(this.currentAcademyCourse.data.content, contentGroup => {
          indexes = indexes.concat(contentGroup.sheets)
        })
        return indexes
      }
      return [];
    },
    currentAcademyCourseSheetsIndexesReversed () {
      return _.invert(this.currentAcademyCourseSheetsIndexes);
    },
    currentAcademyCourseTitle () {
      if (this.currentAcademyCourse) {
        return this.currentAcademyCourse.data.name
      }
      return '';
    },
    currentAcademyCourseSubtitle () {
      if (this.currentAcademyCourse) {
        return this.currentAcademyCourse.data.category
      }
      return '';
    },
    currentAcademyCourseSlide () {
      if (this.currentAcademyCourse) {
        return this.currentAcademyCourse.data.content_detail[this.currentAcademyCourseSheetsIndexes[this.currentAcademyCourseStep]]
      }
      return null
    },
    currentAcademyCourseSlideIsLast () {
      if (this.currentAcademyCourseSlide) {
        return this.currentAcademyCourseSlide >= this.currentAcademyCourse.data.content_detail - 1
      }
      return false
    },
    currentUser () {
      return this.$store.getters['auth/getProfile']
    },
    areFiltersClean () {
      return this.$store.getters['global/areFiltersClean']
    },
    isSidebarRightOpened () {
      return this.$store.getters['global/isSidebarRightOpened']
    },
    isCurrentWorldOwner () {
      return this.$store.getters['auth/isWorldOwner'];
    },
    contentFormatted () {
      return this.formatContent()
    },
    contentFiltered () {
      if (!this.filters) {
        return []
      }
      if (this.searchValue === '') {
        return this.applyDataFilters(this.contentFormatted, this.filters)
      }

      return new Fuse(this.applyDataFilters(this.contentFormatted, this.filters), {
        shouldSort: false,
        threshold: 0.3,
        location: 0,
        distance: 100,
        minMatchCharLength: 1,
        keys: [
          "data.name",
          "data.description",
        ]
      }).search(this.searchValue)
    },
    searchValue: {
      get () {
        return this.$store.state.global.sidebarSearch
      },
      set (value) {
        this.$store.commit('global/changeSidebarSearch', value)
      }
    }
  },
  watch: {
    searchValue(val) {
      this.$nextTick(() => {
        this.$refs.filterSearch.$refs.filterSearchInput.focus()
      })
    },
    areFiltersClean(val) {
      let renderSidebar = !val
      this.changeSidebarRight(renderSidebar)
    }
  }
}
</script>

<style lang="scss" scoped>
.academycourse-card {
  min-height: 100%;
}
.cat-picto {
  width: 100px;
  height: auto;
}
.academycourse-name {
  border-bottom-width: 3px !important;
}
.summary-courses {
  position: relative;
  &:before {
    position: absolute;
    width: 2px;
    background: #ccc;
    left: 3px;
    top: 30px;
    bottom: -27px;
    content: '';
    display: block;
  }
  &:last-child {
    &:before {
      display: none;
    }
  }
  > i {
    position: absolute;
    left: -4px;
    top: 15px;
    &.icon-checkmark-circle {
      transform: scale(1.3);
    }
  }
}
.content-form-group /deep/ {
  .select2-selection {
    background: #f2f2f2;
    border-color: #f2f2f2 !important;
  }
}
.editor__content {
  max-width: 800px;
  margin: 0 auto;
}
/deep/ .pilote-embed-video {
  video {
    min-height: 400px;
  }
}
</style>
